import React from "react";

export const Footer = () => {
  return (
    <footer className="bg-transparent text-white pt-20 border-t-2 border-white">
      <div className="container mx-auto px-4">
        <div className="flex flex-col gap-8 md:flex-row md:justify-between">
          <div className="flex flex-col sm:flex-row gap-4 sm:gap-24 text-lg mx-auto text-center">
            <a href="#introduction" className="text-white hover:underline">Introduction</a>
            <a href="#tokenomics" className="text-white hover:underline">Tokenomics</a>
            <a href="#presale-stage" className="text-white hover:underline">Presale Stage</a>
            <a href="#roadmap" className="text-white hover:underline">Roadmap</a>
          </div>
        </div>
        <div className="flex flex-row gap-5 text-lg mt-24">
            <span className="text-center mx-auto">Contact Us: karl@heartchain.online</span>
          </div>
        <div className="text-sm opacity-50 text-center mt-5">
          © 2024 HeartChain Inu. All rights reserved.
        </div>
      </div>
    </footer>
  );
};
