"use client";

import React, { useReducer, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { ConnectButton, useWalletKit } from "@mysten/wallet-kit";
import { OBJECT_RECORD, LAMPORT } from "../../config";
import {
  getAccount,
  useGetBalance,
  useGetFarm,
  useGetPendingRewards,
  useGetPoolInfo,
  useStakingMethods,
} from "../../hooks";
import BigNumber from "bignumber.js";
import { convertFloat, convertTimestampToDateFormat } from "../../utils";
import { useStakings } from "../../context";
import { useLocation } from "react-router-dom"; // Reverted to react-router-dom
import { AnimatedSection, AnimatedDiv, AnimatedH2, AnimatedH3, AnimatedP, AnimatedSpan, AnimatedStrong, AnimatedImg, AnimatedButton, AnimatedInput } from "../home/AnimatedComponents";
import { buttonHoverVariant } from "../home/animations";

export const PresaleSection = () => {
  const { currentAccount } = useWalletKit();
  const { state, changeVariable, writeAccount } = useStakings();

  // Countdown logic
  const [timeLeft, setTimeLeft] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  });

  const calculateTimeLeft = () => {
    const utcNow = Date.now();
    // futureDate defines the date and time the countdown will target, set it as needed
    // It will never go negative, and if countdown finished, it will stop at zero
    const futureDate = new Date("2024-12-14T15:00:00Z");
    const difference = futureDate.getTime() - utcNow;

    let days = 0;
    let hours = 0;
    let minutes = 0;
    let seconds = 0;

    if (difference > 0) {
      days = Math.floor(difference / (1000 * 60 * 60 * 24));
      hours = Math.floor(
        (difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
      seconds = Math.floor((difference % (1000 * 60)) / 1000);
    }

    setTimeLeft({ days, hours, minutes, seconds });
  };

  useEffect(() => {
    calculateTimeLeft();
    const interval = setInterval(() => calculateTimeLeft(), 1000);
    return () => clearInterval(interval);
  }, []);

  const [updateNumber, forceUpdate] = useReducer((x) => x + 1, 0);
  const update = () => {
    setTimeout(() => {
      forceUpdate();
    }, 500);
  };

  const parameter = useLocation().search;
  const referral = new URLSearchParams(parameter).get("referral");

  const { staking, claim, setClaimToken } = useStakingMethods();
  const [unStake, setUnStake] = useState(false);

  // ---------- Get Farming details -----------
  const userData = useGetFarm(
    OBJECT_RECORD.COIN_SUI,
    currentAccount?.address || OBJECT_RECORD.ADDRESSZERO,
    updateNumber
  );
  const userStakedAmount = userData[0] ? userData[0] / LAMPORT : 0;
  const userClaimableAmount = userData[1] ? userData[1] / LAMPORT : 0;

  // ---------- Get Balance details -----------
  const currentBalance = useGetBalance(
    currentAccount?.address || OBJECT_RECORD.ADDRESSZERO,
    updateNumber
  );
  // -------------------------------------------

  // ---------- Get POOL INFO -----------
  const { status, poolInfo } = useGetPoolInfo(updateNumber);
  // ------------------------------------
  
  const [address, setAddress] = useState<any>("");
  useEffect(() => {
    if (currentAccount?.address) {
      let tempAddr = currentAccount?.address;
      setAddress(tempAddr.slice(0, 4) + "..." + tempAddr.slice(-4));
    } else setAddress("");
  }, [currentAccount]);

  const [amount, setAmount] = useState<any>(0);
  const maxFunction = () => {
    if (unStake) {
      setAmount(userStakedAmount);
    } else {
      setAmount(
        currentAccount?.address && parseFloat(currentBalance) > 0.3
          ? convertFloat(parseFloat(currentBalance) - 0.1)
          : 0
      );
    }
  };

  const receiveTokenAmount = Number(amount) * 3000;
  const handleStake = async (amount) => {
    let tx = await staking(amount);
    let status: string = tx!["effects"]!["status"]!["status"] || "failure";
    let error: string =
      tx!["effects"]!["status"]!["error"] ||
      "We are sorry. Please try later again.";

    if (status === "failure") {
      toast(error, {
        type: "error",
      });
    } else {
      toast("Contribution successful", {
        type: "success",
      });
      setTimeout(() => {
        updateAccount(currentAccount.address);
        if (referral) updateAccount(referral);
      }, 200);
    }
    update();
  };

  const handleClaimToken = async () => {
    let tx = await setClaimToken();

    let status: string = tx!["effects"]!["status"]!["status"] || "failure";
    let error: string =
      tx!["effects"]!["status"]!["error"] ||
      "We are sorry. Please try later again.";

    if (status === "failure") {
      toast(error, {
        type: "error",
      });
    } else {
      toast("Token claimed successfully!", {
        type: "success",
      });
      setTimeout(() => {
        updateAccount(currentAccount.address);
        if (referral) updateAccount(referral);
      }, 200);
    }
    update();
  };

  const updateAccount = async (account: string) => {
    let results = await getAccount(account);
    writeAccount(
      account,
      results["TotalStaked"],
      results["TotalRewards"],
      results["TotalUsers"]
    );
  };

  const [contractAddress, setContractAddress] = useState(
    "0x264a86d8ed985c65e358e66316323f0aa0bb7df50c0cf6c57abc43cdb9c4edf1"
  );
  const [isCopied, setIsCopied] = useState(false);

  const handleCopyAddress = () => {
    navigator.clipboard.writeText(contractAddress);
    setIsCopied(true);
    setTimeout(() => {
      setIsCopied(false);
    }, 2000);
  };

  // Formatted countdown values
  const formattedDays = timeLeft.days.toString().padStart(2, "0") + ":";
  const formattedHours = timeLeft.hours.toString().padStart(2, "0") + ":";
  const formattedMinutes = timeLeft.minutes.toString().padStart(2, "0") + ":";
  const formattedSeconds = timeLeft.seconds.toString().padStart(2, "0");

  return (
    <AnimatedSection id="presale" className="bg-transparent text-white py-30 border-t-2 border-[#fff] lg:border-t-0 px-20 lg:px-60" duration={0.25} delay={0.02}>
      <AnimatedDiv className="mx-auto px-4" duration={0.25} delay={0.02}>
        <AnimatedH2 className="text-30 md:text-40 lg:text-50 font-bold text-center" duration={0.25} delay={0.04}>
          HeartChain INU Presale
        </AnimatedH2>
        <AnimatedImg src="/couple.png" alt="Couple" width="240px" className="mx-auto" duration={0.25} delay={0.06} />
        <AnimatedDiv className="flex-1 flex flex-col items-center" duration={0.25} delay={0.08}>
          <AnimatedDiv className="flex flex-col gap-30 items-center" duration={0.25} delay={0.1}>
            <AnimatedDiv className="flex flex-col items-center text-center" duration={0.25} delay={0.12}>
              <AnimatedSpan className="text-30 md:text-20 lg:text-30 font-bold" duration={0.25} delay={0.14}>
                Presale Stage 1
              </AnimatedSpan>
              <AnimatedSpan className="text-12 md:text-15 lg:text-18 opacity-50" duration={0.25} delay={0.16}>
                1 SUI = 3,000 $HTCN
              </AnimatedSpan>
            </AnimatedDiv>
            <AnimatedDiv className="flex items-start w-full gap-32 count-down-main justify-around mr-24" duration={0.25} delay={0.18}>
              <AnimatedDiv className="timer w-40" duration={0.25} delay={0.2}>
                <AnimatedDiv className="border-gray-300 bg-gray-900/50 h-64 w-72 rounded-lg overflow-hidden" duration={0.25} delay={0.22}>
                  <AnimatedH3 className="countdown-element days font-Cormorant font-semibold text-5xl text-white text-center py-10" duration={0.25} delay={0.24}>
                    {formattedDays.slice(0, -1)}
                  </AnimatedH3>
                </AnimatedDiv>
                <AnimatedP className="text-lg font-Cormorant font-medium text-gray-300 mt-1 text-center w-full ml-12" duration={0.25} delay={0.26}>
                  days
                </AnimatedP>
              </AnimatedDiv>
              <AnimatedDiv className="timer w-40" duration={0.25} delay={0.28}>
                <AnimatedDiv className="border-gray-300 bg-gray-900/50 h-64 w-72 rounded-lg overflow-hidden" duration={0.25} delay={0.3}>
                  <AnimatedH3 className="countdown-element hours font-Cormorant font-semibold text-5xl text-white text-center py-10 animate-countinsecond" duration={0.25} delay={0.32}>
                    {formattedHours.slice(0, -1)}
                  </AnimatedH3>
                </AnimatedDiv>
                <AnimatedP className="text-lg font-Cormorant font-normal text-gray-300 mt-1 text-center w-full ml-12" duration={0.25} delay={0.34}>
                  hours
                </AnimatedP>
              </AnimatedDiv>
              <AnimatedDiv className="timer w-40" duration={0.25} delay={0.36}>
                <AnimatedDiv className="border-gray-300 bg-gray-900/50 h-64 w-72 rounded-lg overflow-hidden" duration={0.25} delay={0.38}>
                  <AnimatedH3 className="countdown-element minutes font-Cormorant font-semibold text-5xl text-white text-center py-10 animate-countinsecond" duration={0.25} delay={0.4}>
                    {formattedMinutes.slice(0, -1)}
                  </AnimatedH3>
                </AnimatedDiv>
                <AnimatedP className="text-lg font-Cormorant font-normal text-gray-300 mt-1 text-center w-full" duration={0.25} delay={0.42}>
                  minutes
                </AnimatedP>
              </AnimatedDiv>
              <AnimatedDiv className="timer w-40" duration={0.25} delay={0.44}>
                <AnimatedDiv className="border-gray-300 bg-gray-900/50 h-64 w-72 rounded-lg overflow-hidden" duration={0.25} delay={0.46}>
                  <AnimatedH3 className="countdown-element seconds font-Cormorant font-semibold text-5xl text-white text-center py-10 animate-countinsecond" duration={0.25} delay={0.48}>
                    {formattedSeconds}
                  </AnimatedH3>
                </AnimatedDiv>
                <AnimatedP className="text-lg font-Cormorant font-normal text-gray-300 mt-1 text-center w-full" duration={0.25} delay={0.5}>
                  seconds
                </AnimatedP>
              </AnimatedDiv>
            </AnimatedDiv>
          </AnimatedDiv>
        </AnimatedDiv>
        <AnimatedDiv className="" duration={0.25} delay={0.52}>
          <AnimatedDiv className="flex-1 flex flex-col items-center" duration={0.25} delay={0.54}>
            {/* <AnimatedDiv className="flex flex-row gap-20 flex-wrap justify-center">
              <AnimatedDiv className="w-250 flex flex-col gap-5 mm:gap-10 lg:gap-15 border-gray-300 bg-gray-900/50 px-10 mm:px-15 lg:px-20 py-10 mm:py-15 lg:py-20 rounded-md">
                <span className="text-10 mm:text-12 opacity-50">
                  Total SUI Staked
                </span>
                <span className="text-15 mm:text-20 font-bold">
                  {state["totalStaked"]}
                </span>
              </AnimatedDiv>

              <AnimatedDiv className="w-250 flex flex-col gap-5 mm:gap-10 lg:gap-15 border-gray-300 bg-gray-900/50 px-10 mm:px-15 lg:px-20 py-10 mm:py-15 lg:py-20 rounded-md">
                <span className="text-10 mm:text-12 opacity-50">
                  Last Reward Time
                </span>
                <span className="text-15 mm:text-20 font-bold">
                  {state["lastRewardTime"]}
                </span>
              </AnimatedDiv>

              <AnimatedDiv className="w-250 flex flex-col gap-5 mm:gap-10 lg:gap-15 border-gray-300 bg-gray-900/50 px-10 mm:px-15 lg:px-20 py-10 mm:py-15 lg:py-20 rounded-md">
                <span className="text-10 mm:text-12 opacity-50">
                  Accrued token Per Share
                </span>
                <span className="text-15 mm:text-20 font-bold">
                  {state["accruedToken"]}
                </span>
                <span className="text-12 mm:text-15 font-semibold"></span>
              </AnimatedDiv>

              <AnimatedDiv className="w-250 flex flex-col gap-5 mm:gap-10 lg:gap-15 border-gray-300 bg-gray-900/50 px-10 mm:px-15 lg:px-20 py-10 mm:py-15 lg:py-20 rounded-md">
                <span className="text-10 mm:text-12 opacity-50">
                  Allocation Points
                </span>
                <span className="text-15 mm:text-20 font-bold">
                  {state["allocationProfit"]}
                </span>
                <span className="text-12 mm:text-15 font-semibold"></span>
              </AnimatedDiv>
            </AnimatedDiv> */}
            <AnimatedDiv className="ml-0 flex flex-col gap-30 items-center max-w-[90%]" duration={0.25} delay={0.56}>
              <AnimatedDiv className="flex flex-col items-center text-center" duration={0.25} delay={0.58}>
                <span className="text-30 md:text-40 lg:text-50 font-bold">
                  Presale
                </span>
                <span className="text-12 md:text-15 lg:text-18 opacity-50">
                  Stake SUI and receive $HTCN
                </span>
              </AnimatedDiv>
              <AnimatedDiv className="flex flex-row gap-5 p-5 border-gray-300 bg-gray-900/50 rounded-lg" duration={0.25} delay={0.6}>
                <AnimatedDiv onClick={() => { setUnStake(false); }} className={`w-100 md:w-120 lg:w-150 py-5 md:py-8 lg:py-10 ${!unStake && "bg-[#e7aa5a]" } rounded-lg text-center cursor-pointer switch-item`} duration={0.25} delay={0.62}>
                  <span className="text-15 md:text-18 lg:text-20 font-semibold">
                    Stake
                  </span>
                </AnimatedDiv>
                <AnimatedDiv onClick={() => { setUnStake(true); }} className={`w-100 md:w-120 lg:w-150 py-5 md:py-8 lg:py-10 ${unStake && "bg-[#e7aa5a]" } rounded-full text-center cursor-pointer switch-item`} duration={0.25} delay={0.64}>
                  <span className="text-15 md:text-18 lg:text-20 font-semibold">
                    Claim
                  </span>
                </AnimatedDiv>
              </AnimatedDiv>
              <AnimatedDiv className="w-full max-w-500 flex flex-col gap-15 md:gap-18 lg:gap-20 px-15 md:px-18 lg:px-20 py-15 md:py-18 lg:py-20 border-gray-300 bg-gray-900/50 rounded-lg" duration={0.25} delay={0.66}>
                <AnimatedDiv className="flex flex-col px-15 md:px-18 lg:px-20 py-10 md:py-12 lg:py-15 bg-[#777]/50 rounded-md" duration={0.25} delay={0.68}>
                  <AnimatedDiv className="flex flex-row gap-10 justify-between items-center py-10 md:py-15 lg:py-20" duration={0.25} delay={0.7}>
                    <AnimatedDiv className="flex flex-row gap-5 md:gap-10 items-center" duration={0.25} delay={0.72}>
                      {unStake ?
                        <AnimatedImg src="/logo.png" alt="Personalized NFTs" width="48px" className="w-[48px] h-[48px] mb-5" duration={0.25} delay={0.74} />
                        :
                        <AnimatedImg src="/sui.png" alt="Personalized NFTs" width="48px" className="w-[48px] h-[48px] mb-5" duration={0.25} delay={0.74} />
                      }
                      <span className="text-15 md:text-18 lg:text-20 font-semibold">
                        {!unStake ? "SUI" : "HTCN"}
                      </span>
                    </AnimatedDiv>
                    <AnimatedDiv className="flex flex-row gap-10 items-center" duration={0.25} delay={0.76}>
                      {/* <span className="text-12 md:text-15 opacity-50">
                        SUI amount to presale participate
                      </span> */}
                    </AnimatedDiv>
                  </AnimatedDiv>
                  {!unStake && (
                    <AnimatedDiv className="py-5 md:py-8 lg:py-10 mx-auto" duration={0.25} delay={0.78}>
                      <AnimatedDiv className="flex flex-col sm:flex-row gap-10 text-12 md:text-15 opacity-50 items-center" duration={0.25} delay={0.8}>
                        <span className="flex flex-row">Amount:</span>
                        <AnimatedInput type="number" placeholder="0" className="text-right p-5 border-2 rounded-lg bold" value={amount} step={0.001} onChange={(e) => {
                          let value = Number(e.target.value);
                          if (value > 0) {
                            setAmount(value);
                          } else {
                            setAmount(0);
                          }
                        }} duration={0.25} delay={0.82} />
                        <AnimatedSpan className="text-15 md:text-14 lg:text-14 font-bold text-white cursor-pointer border-2 rounded-lg p-4" onClick={maxFunction} duration={0.25} delay={0.84}>
                          MAX
                        </AnimatedSpan>
                      </AnimatedDiv>
                    </AnimatedDiv>
                  )}
                  {!unStake && (
                    <>
                      <AnimatedDiv className="flex flex-row justify-between" duration={0.25} delay={0.86}>
                        <span className="text-12 md:text-15 opacity-80">
                          You will receive
                        </span>
                        <span className="text-12 md:text-15 opacity-80">
                          {receiveTokenAmount ? receiveTokenAmount.toFixed(4) : 0} HTCN
                        </span>
                      </AnimatedDiv>
                      {!!address && (
                        <AnimatedDiv onClick={() => { handleStake(amount * LAMPORT); }} className="bg-[#e7aa5a] px-10 py-10 md:py-15 rounded-lg text-center cursor-pointer text-12 md:text-15 font-semibold hover:scale-110 transition-transform duration-200" duration={0.25} delay={0.88}>
                          <AnimatedSpan>Stake</AnimatedSpan>
                        </AnimatedDiv>
                      )}
                      {/* {!!address && (
                        <AnimatedDiv onClick={() => { handleClaimToken(); }} className="bg-[#e7aa5a] px-10 py-10 md:py-15 rounded-lg text-center cursor-pointer text-12 md:text-15 font-semibold" initial={{ y: 25, opacity: 0 }} animate={{ y: 0, opacity: 1 }} transition={{ duration: 0.5 * duration_coefficient, delay: 4.8 * delay_coefficient }}>
                          <AnimatedSpan>Set Claim Token</AnimatedSpan>
                        </AnimatedDiv>
                      )} */}
                    </>
                  )}
                  {!!unStake && (
                    <>
                      <AnimatedDiv className="grid grid-cols-2 gap-10 md:gap-15 lg:gap-20 pb-10" duration={0.25} delay={0.9}>
                        <AnimatedDiv className="flex flex-col gap-10 md:gap-15 lg:gap-20 p-10 md:p-15 lg:p-20 border-gray-300 bg-gray-900/50 rounded-md" duration={0.25} delay={0.92}>
                          <AnimatedDiv className="flex flex-col gap-5 md:gap-10" duration={0.25} delay={0.94}>
                            <span className="text-12 md:text-13">
                              Staked
                            </span>
                            <AnimatedDiv className="flex flex-row gap-5 items-center" duration={0.25} delay={0.96}>
                              <span className="text-16 md:text-16">
                                {userStakedAmount}
                              </span>
                              SUI
                            </AnimatedDiv>
                          </AnimatedDiv>
                        </AnimatedDiv>
                        <AnimatedDiv className="flex flex-col gap-10 md:gap-15 lg:gap-20 p-10 md:p-15 lg:p-20 border-gray-300 bg-gray-900/50 rounded-md" duration={0.25} delay={0.98}>
                          <AnimatedDiv className="flex flex-col gap-5 md:gap-10" duration={0.25} delay={1}>
                            <span className="text-12 md:text-13">
                              Pending
                            </span>
                            <AnimatedDiv className="flex flex-row gap-5 items-center" duration={0.25} delay={1.02}>
                              <span className="text-16 md:text-16">
                                {userClaimableAmount}
                              </span>
                              $HTCN
                            </AnimatedDiv>
                          </AnimatedDiv>
                        </AnimatedDiv>
                      </AnimatedDiv>
                      {!!address && (
                        <AnimatedDiv onClick={() => { claim(); }} className="bg-[#e9aa5a] px-10 py-10 md:py-15 rounded-lg text-center cursor-pointer text-12 md:text-15 font-semibold hover:scale-110 transition-transform duration-200" duration={0.25} delay={1.04}>
                          <AnimatedSpan>Claim $HTCN</AnimatedSpan>
                        </AnimatedDiv>
                      )}
                    </>
                  )}
                  {!address && (
                    <AnimatedDiv duration={0.25} delay={1.06}>
                      <ConnectButton
                        connectedText={address}
                        connectText={"Connect Wallet"}
                        className="connect-btn px-10 py-8 rounded-lg text-center cursor-pointer text-12 md:text-20 font-semibold bg-gray-900/50 border border-gray-300 w-full hover:scale-105 transition-transform duration-200"
                      />
                    </AnimatedDiv>
                  )}
                </AnimatedDiv>
              </AnimatedDiv>

              <AnimatedDiv className="rounded-lg border border-gray-300 bg-gray-900/50 p-8 max-w-500 text-center" duration={0.25} delay={1.08}>
                <AnimatedStrong className="text-2xl font-bold mb-2" duration={0.25} delay={1.1}>
                  Contract Address:
                </AnimatedStrong>
                <AnimatedDiv className="border-gray-300 border bg-[#777]/50 px-4 my-8 rounded-lg overflow-hidden" duration={0.25} delay={1.12}>
                  <AnimatedP className="truncate opacity-75 max-w-[80vw]" duration={0.25} delay={1.14}>
                    {contractAddress ? `${contractAddress.slice(0, 50)}...${contractAddress.slice(-4)}` : ""}
                    {/* {contractAddress} */}
                  </AnimatedP>
                </AnimatedDiv>
                <AnimatedButton type="button" onClick={handleCopyAddress} className="mt-5 bg-[#e9aa5a] px-16 py-8 rounded-lg text-center cursor-pointer font-semibold text-white text-20" duration={0.25} delay={1.16}>
                  {isCopied ? "Copied!" : "Copy"}
                </AnimatedButton>
              </AnimatedDiv>
            </AnimatedDiv>
          </AnimatedDiv>
        </AnimatedDiv>
      </AnimatedDiv>
    </AnimatedSection>
  );
};
