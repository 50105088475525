export const OBJECT_RECORD = {
    PACKAGEID:
        '0x92afb714d298193986b57cd5d9ac72ce87da666749ebd7ba1ddeeb4d4d31979b',
    USERSTATE:
        '0x350befcb74a8bf52b04774800f37439e8c9e2f609019a3cd7d46a33ba5f4d8d6',
    ADMINCAP:
        '0x9af5d49e14e2ef5cc67768f1d5fb01efb885f69bdf85c07cb5c91c5214610c83', //admin auth
    TREASURY:
        '0x2bb09c42763ab1ebd7e361bf436e8bbaeb70213fd6cbab0feb06cb51bda9b0fd',
    PRESALEPOOL:
        "0x7c492771da04bad97abac7f8a14c24ee573dd114dc197345668d603a69a20170",
    TOKENTREASURYCAP:
        '0xbb03aa4857721331e05cc921c4ef094bda7302f6e35339ee6c1651ccc04a7a09',
    TOKEN:
        '0x264a86d8ed985c65e358e66316323f0aa0bb7df50c0cf6c57abc43cdb9c4edf1',
    ADDRESSZERO:
        '0x0000000000000000000000000000000000000000000000000000000000000000',
    CLOCKOBJECT:
        "0x0000000000000000000000000000000000000000000000000000000000000006",
    OWNERADDRESS:
        "0x3d473e7604adbba8296718d5ebf5c49e4b95fa11ec56612d590f63db61550b9a",
    COIN_SUI: "0x2::sui::SUI"
    // PACKAGEID:
    //     '0x9f952f74bb19cbd67988bb3c24f85f2f4b059f0a866d058951aa8bf827f5d349',
    // USERSTATE:
    //     '0xd48da844dced4bce802166c0275f55bde8b65808d7d28c69725450588c0b6ea1',
    // ADMINCAP:
    //     '0x2fe729d5e26623b52e5d5116b199ebbbdac80ce1276c1ce12cc018b1b52478d1', //admin auth
    // TREASURY:
    //     '0x22ee685e89a294cbdffb63cdb717cbc39f434d5edfb5aab3540e694cc47f89b9',
    // PRESALEPOOL:
    //     "0xd41a939d85b47089ae458e160743e0c3efb60c2d51e7654a5ef050ce72e747a0",
    // TOKENTREASURYCAP:
    //     '0xdacf9471cd1083f7cd8c12a03f3df776ed126cefd900bcf931381a3c6db32c46',
    // TOKEN:
    //     '0x330e5d821286e71b42ca5528c5b10fead3ccd0bb8266fe29fc05fe48977f7176',
    // ADDRESSZERO:
    //     '0x0000000000000000000000000000000000000000000000000000000000000000',
    // CLOCKOBJECT:
    //     "0x0000000000000000000000000000000000000000000000000000000000000006",
    // OWNERADDRESS:
    //     "0x3d473e7604adbba8296718d5ebf5c49e4b95fa11ec56612d590f63db61550b9a",
    // COIN_SUI: "0x2::sui::SUI"
};

export const LAMPORT = 1000000000;
